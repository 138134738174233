import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { applyMiddleware, createStore } from 'redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import initialState from './initialState';
import createRootReducer from './reducers';
import firebase from 'firebase/app'
import App from './App';

import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/remote-config';

const rrfConfig = {
  logErrors: true,
  // enableLogging: true,
  logListenerError: true,
}

const firebaseConfig = {
  apiKey: "AIzaSyASoWx5eP2PZS0ETfHmDp8QukhVFZXd-Us",
  authDomain: "the-next-50.firebaseapp.com",
  databaseURL: "https://the-next-50.firebaseio.com",
  projectId: "the-next-50",
  storageBucket: "the-next-50.appspot.com",
  messagingSenderId: "9482063692",
  appId: "1:9482063692:web:5c16a05c8cd28bf4e7c9e9",
  measurementId: "G-PBTH29JTQV"
};
firebase.initializeApp(firebaseConfig)
firebase.analytics();
// const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 360000,
// };
// remoteConfig.defaultConfig = ({
//   'advising_mode': 'advising_mode',
// });

export const history = createBrowserHistory()
const middleware = applyMiddleware(
  routerMiddleware(history),
  // thunkMiddleware
  // createLogger()
);

const store = createStore(
  createRootReducer(history),
  initialState,
  middleware
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.register();
