import React, { Component } from 'react';
import RecommendationCard from './RecommendationCard';

class CandidateCard extends Component {
  render() {
    const { candidate, ...otherProps } = this.props;

    const {
      name,
      investmentAvenue,
      officeText,
      donationLink,
      validatingOrgs,
    } = candidate;

    return (
      <RecommendationCard
        title={name}
        type="Candidate"
        className="candidate"
        donationLink={donationLink}
        validatingOrgs={validatingOrgs}
        {...otherProps}>
        <p>Running for {investmentAvenue} in {officeText}.</p>
      </RecommendationCard>
    );
  }
}

export default CandidateCard;
