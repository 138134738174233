import React, { PureComponent } from 'react';
import DefinedTerm from '../DefinedTerm';

class RecommendationCard extends PureComponent {

  render() {
    const {
      title,
      subtitle,
      className,
      type,
      children,
      donationLink,
      justifications,
      validatingOrgs,
      terms,
    } = this.props;

    const justificationText = justifications.join(', ')

    return (
      <li className={`recommendation ${className}`}>
        <label>{ type }</label>
        <h4><span className={`title ${subtitle && 'with-subtitle'}`}>{ title }</span> { subtitle && (<span className="h5">{subtitle}</span>)}</h4>
        { children }
        <div className="button-group">
          <a href={donationLink} tabIndex="0" target="_blank" rel="noopener noreferrer" className="button blue">Donate</a>
        </div>
        <ul className="factors">
          {
            validatingOrgs.map((vo, dx) => (
              <li key={"validatingOrgs-" + dx}>
                <span className="endorsement">Endorsed by</span>
                <div><DefinedTerm key={dx} term={terms.get(vo)} /></div>
              </li>
            ))
          }
        </ul>
        {
          justifications.length > 0 && (
            <div className="justifications"><label>You selected:</label> <span className="list">{justificationText}</span></div>
          )
        }
      </li>
    );
  }
}

export default RecommendationCard;
