import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AdvisingPage from './pages/AdvisingPage';
import 'moment-timezone';

import './App.scss';

const enhance = compose(
  withRouter,
  connect(({ firebase: { auth } }) => ({ auth }))
);

class App extends Component {
  render() {
    const { auth } = this.props;
    let content;
    if (!isLoaded(auth)) {
      content = <div className="root-loading"></div>
    } else {
      content = (
        <Switch>
          <Route component={AdvisingPage} exact path="/advising" />
          <Route component={AdvisingPage} exact path="/" />
        </Switch>
      );
    }

    return (
      <React.Fragment>
        <Route exact path="/logout" component={LogoutPage}></Route>
        { content }
      </React.Fragment>
    );
  }
}

export default enhance(App);
