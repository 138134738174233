
class Recommendation {
  constructor(json) {
    this.donationLink = json.tn50DonationLink;
    this.validatingOrgs = this._parseList(json.validatingOrganizations);
    this.issues = this._parseList(json.issues);
    this.constituencies = this._parseList(json.identity);
  }

  _parseList(rawList) {
    if (!rawList) {
      return [];
    }
    return rawList.split(',').map(i => i.trim());
  }
}

export class Candidate extends Recommendation {
  constructor(json) {
    super(json);
    this.name = json.generalElectionCandidate;
    this.locationState = json.officeLocationState;
    this.officeText = (
      json.officeTheyAreRunningFor === 'House'
      ? `${json.officeLocationState}-${json.districtNumber}`
      : json.officeLocationState
    );
    this.investmentAvenue = json.raceType;
  }
}

export class Organization extends Recommendation {
  constructor(json) {
    super(json);
    this.name = json.name;
    this.description = json.description;
    this.investmentAvenue = json.organizationType;
    this.locationState = json.operatingLocationState;
  }
}
