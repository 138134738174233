export const QuestionTypes = {
  MULTIPLE_CHOICE: "multiple-choice",
  MULTIPLE_CHOICE_DROPDOWN: "multiple-choice-dropdown",
  SINGLE_CHOICE: "single-choice",
};

export const FORM_QUESTIONS = [
  // {
  //   question: "Roughly how much do you have available to give a month? The equivalent of a:",
  //   title: 'Disposable Income',
  //   type: QuestionTypes.SINGLE_CHOICE,
  //   id: 'disposable-income',
  //   options: [
  //     {
  //       htmlId: 'coffee',
  //       value: 'Coffee',
  //       label: 'Coffee',
  //     },
  //     {
  //       htmlId: 'brunch',
  //       value: 'Brunch',
  //       label: 'Brunch',
  //     },
  //     {
  //       htmlId: 'concert',
  //       value: 'Concert',
  //       label: 'Concert',
  //     },
  //     {
  //       htmlId: 'vacation',
  //       value: 'Vacation',
  //       label: 'Vacation',
  //     },
  //     {
  //       htmlId: 'more',
  //       value: 'More',
  //       label: 'More',
  //     },
  //   ],
  // },
  {
    question: "Are there any issues that are especially important to you?",
    title: 'Issues',
    type: QuestionTypes.MULTIPLE_CHOICE,
    id: 'issues',
    options: [
      {
        htmlId: "issue-criminal-justice",
        value: "Criminal justice",
        label: "Criminal justice",
      },
      {
        htmlId: "issue-economy-jobs",
        value: "Economy / jobs",
        label: "Economy / jobs",
      },
      {
        htmlId: "issue-education",
        value: "Education",
        label: "Education",
      },
      {
        htmlId: "issue-climate-change",
        value: "Environment / climate change",
        label: "Environment / climate change",
      },
      {
        htmlId: "issue-foreign-policy",
        value: "Foreign policy",
        label: "Foreign policy",
      },
      {
        htmlId: "issue-gun-violence",
        value: "Gun violence",
        label: "Gun violence",
      },
      {
        htmlId: "issue-health-care",
        value: "Health Care",
        label: "Health care",
      },
      {
        htmlId: "issue-immigration",
        value: "Immigration",
        label: "Immigration",
      },
      {
        htmlId: "issue-lgbtq-issues",
        value: "LGBTQ+ issues",
        label: "LGBTQ+ issues",
      },
      {
        htmlId: "issue-national-security",
        value: "National security",
        label: "National security",
      },
      {
        htmlId: "issue-racial-justice",
        value: "Racial justice",
        label: "Racial justice",
      },
      {
        htmlId: "issue-rural-community-issues",
        value: "Rural issues",
        label: "Rural issues",
      },
      {
        htmlId: "issue-voting-rights",
        value: "Voting rights",
        label: "Voting rights",
      },
      {
        htmlId: "issue-womens-rights",
        value: "Women's rights",
        label: "Women's rights",
      }
    ]
  },
  {
    question: 'Are there any constituencies that are especially important to you?',
    title: 'Constituencies',
    type: QuestionTypes.MULTIPLE_CHOICE,
    id: 'constituencies',
    options: [
      {
        htmlId: 'asian-american',
        value: 'Asian American',
        label: 'Asian American',
      },
      {
        htmlId: 'black-african-american',
        value: 'Black / African American',
        label: 'Black / African American',
      },
      {
        htmlId: 'jewish',
        value: 'Jewish',
        label: 'Jewish',
      },
      {
        htmlId: 'latinx',
        value: 'Latinx',
        label: 'Latinx',
      },
      {
        htmlId: 'lgbtq',
        value: 'LGBTQ',
        label: 'LGBTQ',
      },
      {
        htmlId: 'low-income',
        value: 'Low income',
        label: 'Low income',
      },
      {
        htmlId: 'native-american-pacific-islander',
        value: 'Native American / Pacific Islander',
        label: 'Native American / Pacific Islander',
      },
      {
        htmlId: 'muslim',
        value: 'Muslim',
        label: 'Muslim',
      },
      {
        htmlId: 'women',
        value: 'Women',
        label: 'Women',
      },
      {
        htmlId: 'youth',
        value: 'Youth',
        label: 'Youth',
      },
    ]
  },
  {
    question: 'Are any of the following states or territories important to you?',
    title: 'States',
    type: QuestionTypes.MULTIPLE_CHOICE_DROPDOWN,
    id: 'locationState',
    options: [
      { htmlId: 'state-AK', value: 'AK', label: 'AK – Alaska', },
      { htmlId: 'state-AL', value: 'AL', label: 'AL – Alabama', },
      { htmlId: 'state-AR', value: 'AR', label: 'AR – Arkansas', },
      { htmlId: 'state-AS', value: 'AS', label: 'AS – American Samoa', },
      { htmlId: 'state-AZ', value: 'AZ', label: 'AZ – Arizona', },
      { htmlId: 'state-CA', value: 'CA', label: 'CA – California', },
      { htmlId: 'state-CO', value: 'CO', label: 'CO – Colorado', },
      { htmlId: 'state-CT', value: 'CT', label: 'CT – Connecticut', },
      { htmlId: 'state-DC', value: 'DC', label: 'DC – District of Columbia', },
      { htmlId: 'state-DE', value: 'DE', label: 'DE – Delaware', },
      { htmlId: 'state-FL', value: 'FL', label: 'FL – Florida', },
      { htmlId: 'state-GA', value: 'GA', label: 'GA – Georgia', },
      { htmlId: 'state-GU', value: 'GU', label: 'GU – Guam', },
      { htmlId: 'state-HI', value: 'HI', label: 'HI – Hawaii', },
      { htmlId: 'state-IA', value: 'IA', label: 'IA – Iowa', },
      { htmlId: 'state-ID', value: 'ID', label: 'ID – Idaho', },
      { htmlId: 'state-IL', value: 'IL', label: 'IL – Illinois', },
      { htmlId: 'state-IN', value: 'IN', label: 'IN – Indiana', },
      { htmlId: 'state-KS', value: 'KS', label: 'KS – Kansas', },
      { htmlId: 'state-KY', value: 'KY', label: 'KY – Kentucky', },
      { htmlId: 'state-LA', value: 'LA', label: 'LA – Louisiana', },
      { htmlId: 'state-MA', value: 'MA', label: 'MA – Massachusetts', },
      { htmlId: 'state-MD', value: 'MD', label: 'MD – Maryland', },
      { htmlId: 'state-ME', value: 'ME', label: 'ME – Maine', },
      { htmlId: 'state-MI', value: 'MI', label: 'MI – Michigan', },
      { htmlId: 'state-MN', value: 'MN', label: 'MN – Minnesota', },
      { htmlId: 'state-MO', value: 'MO', label: 'MO – Missouri', },
      { htmlId: 'state-MP', value: 'MP', label: 'MP – Northern Mariana Islands', },
      { htmlId: 'state-MS', value: 'MS', label: 'MS – Mississippi', },
      { htmlId: 'state-MT', value: 'MT', label: 'MT – Montana', },
      { htmlId: 'state-NC', value: 'NC', label: 'NC – North Carolina', },
      { htmlId: 'state-ND', value: 'ND', label: 'ND – North Dakota', },
      { htmlId: 'state-NE', value: 'NE', label: 'NE – Nebraska', },
      { htmlId: 'state-NH', value: 'NH', label: 'NH – New Hampshire', },
      { htmlId: 'state-NJ', value: 'NJ', label: 'NJ – New Jersey', },
      { htmlId: 'state-NM', value: 'NM', label: 'NM – New Mexico', },
      { htmlId: 'state-NV', value: 'NV', label: 'NV – Nevada', },
      { htmlId: 'state-NY', value: 'NY', label: 'NY – New York', },
      { htmlId: 'state-OH', value: 'OH', label: 'OH – Ohio', },
      { htmlId: 'state-OK', value: 'OK', label: 'OK – Oklahoma', },
      { htmlId: 'state-OR', value: 'OR', label: 'OR – Oregon', },
      { htmlId: 'state-PA', value: 'PA', label: 'PA – Pennsylvania', },
      { htmlId: 'state-PR', value: 'PR', label: 'PR – Puerto Rico', },
      { htmlId: 'state-RI', value: 'RI', label: 'RI – Rhode Island', },
      { htmlId: 'state-SC', value: 'SC', label: 'SC – South Carolina', },
      { htmlId: 'state-SD', value: 'SD', label: 'SD – South Dakota', },
      { htmlId: 'state-TN', value: 'TN', label: 'TN – Tennessee', },
      { htmlId: 'state-TX', value: 'TX', label: 'TX – Texas', },
      { htmlId: 'state-UT', value: 'UT', label: 'UT – Utah', },
      { htmlId: 'state-VA', value: 'VA', label: 'VA – Virginia', },
      { htmlId: 'state-VI', value: 'VI', label: 'VI – U.S. Virgin Islands', },
      { htmlId: 'state-VT', value: 'VT', label: 'VT – Vermont', },
      { htmlId: 'state-WA', value: 'WA', label: 'WA – Washington', },
      { htmlId: 'state-WI', value: 'WI', label: 'WI – Wisconsin', },
      { htmlId: 'state-WV', value: 'WV', label: 'WV – West Virginia', },
      { htmlId: 'state-WY', value: 'WY', label: 'WY – Wyoming', },
    ],
    suggestions: [
      {
        label: '2020 Swing States',
        options: [
          { htmlId: 'state-AZ', value: 'AZ', label: 'AZ – Arizona', },
          { htmlId: 'state-CO', value: 'CO', label: 'CO – Colorado', },
          { htmlId: 'state-FL', value: 'FL', label: 'FL – Florida', },
          { htmlId: 'state-GA', value: 'GA', label: 'GA – Georgia', },
          { htmlId: 'state-IA', value: 'IA', label: 'IA – Iowa', },
          { htmlId: 'state-ME', value: 'ME', label: 'ME – Maine', },
          { htmlId: 'state-MI', value: 'MI', label: 'MI – Michigan', },
          { htmlId: 'state-NV', value: 'NV', label: 'NV – Nevada', },
          { htmlId: 'state-NC', value: 'NC', label: 'NC – North Carolina', },
          { htmlId: 'state-OH', value: 'OH', label: 'OH – Ohio', },
          { htmlId: 'state-PA', value: 'PA', label: 'PA – Pennsylvania', },
          { htmlId: 'state-TX', value: 'TX', label: 'TX – Texas', },
          { htmlId: 'state-WI', value: 'WI', label: 'WI – Wisconsin', },
        ],
      },
      {
        label: 'Southern border states',
        options: [
          { htmlId: 'state-AZ', value: 'AZ', label: 'AZ – Arizona', },
          { htmlId: 'state-CA', value: 'CA', label: 'CA – California', },
          { htmlId: 'state-NM', value: 'NM', label: 'NM – New Mexico', },
          { htmlId: 'state-TX', value: 'TX', label: 'TX – Texas', },
        ],
      },
    ]
  },
  {
    question: 'Where would you most like to contribute?',
    title: 'Investment avenues',
    type: QuestionTypes.MULTIPLE_CHOICE,
    id: 'investmentAvenue',
    options: [
      {
        htmlId: '501c3',
        value: '501c3',
        label: '501(c)3',
        description: 'Enable education, research, services and training',
      },
      {
        htmlId: '501c4',
        value: '501c4',
        label: '501(c)4',
        description: 'Enable year-round community organizing that helps continuously win elections',
      },
      {
        htmlId: 'presidency',
        value: 'Presidency',
        label: 'Presidency',
        description: 'Beat Donald Trump',
      },
      {
        htmlId: 'senate',
        value: 'U.S. Senate',
        label: 'U.S. Senate',
        description: 'Enable a Democratic President to enact their agenda.',
      },
      {
        htmlId: 'house-of-representatives',
        value: 'U.S. House of Representatives',
        label: 'U.S. House of Representatives',
        description: 'Preserve the last check on the President.',
      },
      {
        htmlId: 'state-legislatures',
        value: 'State Legislatures',
        label: 'State Legislatures',
        description: 'It’s a Census year. Ensure fair representation for a decade.',
      },
      // {
      //   htmlId: 'i-dont-know',
      //   value: 'I don’t know',
      //   label: 'I don’t know',
      // }
    ]
  }
];
