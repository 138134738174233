import * as ActionTypes from '../constants/ActionTypes';

export const clearFilter = (filterQuestion, filterValue) => ({
  type: ActionTypes.CLEAR_FILTER,
  filterQuestion,
});

export const setFilter = (filterQuestion, filterValue) => ({
  type: ActionTypes.SET_FILTER,
  filterQuestion,
  filterValue,
});

export const setFilterMulti = (filterQuestion, filterKey, filterValue) => ({
  type: ActionTypes.SET_FILTER_MULTI,
  filterQuestion,
  filterKey,
  filterValue,
});
