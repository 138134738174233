import React, { PureComponent } from 'react';

import {Candidate, Organization} from '../../models/recommendations';
import CandidateCard from './CandidateCard';
import OrganizationCard from './OrganizationCard';
import './index.scss';

const scoreSort = (a, b) => (b.score - a.score);

class RecommendationList extends PureComponent {
  render() {
    const { scoredOrganizations, scoredCandidates, terms } = this.props;

    if (!scoredCandidates || !scoredOrganizations || !terms) {
      return (
        <div className="RecommendationList">
          <div className="placeholder">
            <span className="Spinner dark" />
          </div>
        </div>
      );
    }

    const mixedList = scoredOrganizations.concat(scoredCandidates);

    const sortedList = mixedList.sort(scoreSort).slice(0, 6);

    return (
      <div className="RecommendationList">
        {
          sortedList.length > 0 ? (
            <ol>
              {
                sortedList.map(({score, response, justifications}, idx) => {
                  if (response instanceof Candidate) {

                    return (
                      <CandidateCard candidate={response} key={idx} terms={terms} justifications={justifications}/>
                    );
                  } else if (response instanceof Organization) {
                    return (
                      <OrganizationCard organization={response} key={idx} terms={terms} justifications={justifications}/>
                    );
                  }
                  return (<span />);
                })
              }
            </ol>
          ) : (
            <p className="light">We didn’t find any matching candidates or organizations for your selections. We’re continually adding more candidates and organizations to our system, but in the meantime, try expanding your search range.</p>
          )
        }
      </div>
    );
  }
}

export default RecommendationList;
