import React, { Component } from 'react';
import RecommendationCard from './RecommendationCard';

class CandidateCard extends Component {
  constructor() {
    super();
    this.state = {
      seeMore: false,
    }
    this.toggleSeeMore = this.toggleSeeMore.bind(this);
  }

  toggleSeeMore() {
    this.setState({seeMore: !this.state.seeMore});
  }

  render() {
    const { organization, ...otherProps } = this.props;
    const { seeMore } = this.state;

    const {
      name,
      investmentAvenue,
      description,
      donationLink,
      validatingOrgs,
    } = organization;

    return (
      <RecommendationCard
        title={name}
        type="Organization"
        className="organization"
        subtitle={investmentAvenue}
        donationLink={donationLink}
        validatingOrgs={validatingOrgs}
        {...otherProps}>
        <p className={`${seeMore && 'show-all'}`}>{ description }</p>
        <button className="text-button see-more-button" onClick={this.toggleSeeMore}>
          { seeMore ? 'See less' : 'Read more'}
        </button>
      </RecommendationCard>
    );
  }
}

export default CandidateCard;
