import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { connectRouter } from 'connected-react-router'
import user from './user';
import filters from './filters';

export default (history) => combineReducers({
  router: connectRouter(history),
  firebase: firebaseReducer,
  user,
  filters,
})
