import React, { PureComponent } from 'react';

import './index.scss';

class DefinedTerm extends PureComponent {
  render() {
    const { term } = this.props;

    return (
      <span className="DefinedTerm">
        <span className="term">{term.name}</span>
        <span className="hover-card">
          <div className="text">{term.text}</div>
        </span>
      </span>
    );
  }
}

export default DefinedTerm;


