export default class Terms {
  constructor(rawTerms) {
    this.lookupTable = {};
    rawTerms.forEach((termObj) => {
      this.lookupTable[termObj.key] = termObj;
    });
  }

  get(term) {
    return this.lookupTable[term];
  }

  getName(term) {
    return this.lookupTable[term].name;
  }

  getText(term) {
    return this.lookupTable[term].text;
  }
}
