import * as types from '../constants/ActionTypes';
import { filters as initialState } from '../initialState';

export default function filters(state = initialState, action) {
  switch (action.type) {

    case types.CLEAR_FILTER:
      return {
        ...state,
        [action.filterQuestion]: undefined,
      }

    case types.SET_FILTER:
      return {
        ...state,
        [action.filterQuestion]: action.filterValue,
      }

    case types.SET_FILTER_MULTI:
      if (action.filterValue === false || action.filterValue === undefined) {
        const newState = {...state};
        delete newState[action.filterQuestion][action.filterKey];
        return newState;
      }

      return {
        ...state,
        [action.filterQuestion]: {
          ...state[action.filterQuestion],
          [action.filterKey]: action.filterValue,
        }
      }

    default:
      return state;
  }
}
