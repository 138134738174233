import React, { PureComponent } from 'react';

import './index.scss';

class Staple extends PureComponent {

  static defaultProps = {
    offset: 48,
  };

  constructor() {
    super();

    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.state = {
      inRAF: false,
      lastWrapperBottom: 0,
      lastWrapperOffset: 0,
      stapleWidth: '',
      isStapled: false,
    };
    return this;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.t = setInterval(this.onResize, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    clearInterval(this.t);
  }


  onScroll() {
    const lastYOffset = window.pageYOffset;
    const { lastWrapperBottom, lastWrapperOffset, lastStapleHeight, inRAF } = this.state;
    const { offset } = this.props;

    if (!inRAF) {
      this.setState({inRAF: true});
      window.requestAnimationFrame(() => {
        if (lastYOffset >= lastWrapperBottom - offset - lastStapleHeight) {
          this.setState({ stapledBottom: true, stapledTop: false });
        } else if (lastYOffset >= lastWrapperOffset - offset) {
          this.setState({ stapledBottom: false, stapledTop: true });
        } else {
          this.setState({ stapledBottom: false, stapledTop: false });
        }
        this.setState({inRAF: false});
      });
    }
  }

  onResize() {
    var wrapperRect = this.wrapper.getBoundingClientRect();
    var stapleRect = this.sidebar.getBoundingClientRect();
    this.setState({
      lastStapleHeight: stapleRect.height,
      lastWrapperOffset: window.pageYOffset + wrapperRect.top,
      lastWrapperBottom: window.pageYOffset + wrapperRect.top + wrapperRect.height,
    });
  }


  render() {
    const { children, sidebar } = this.props;
    const { stapledBottom, stapledTop } = this.state;
    const stapleWidth = (stapledBottom || stapledTop) ? window.getComputedStyle(this.sidebar).width : '';
    const stapleClass = stapledTop ? 'stapled' : (stapledBottom ? 'stapled-bottom' : '');

    return (
      <div className="content pillars-wrapper" ref={w => {this.wrapper = w}}>
          <div className="pillars-cta">
              <div
                className={`pillars-cta-inner last ${stapleClass}`}
                ref={w => {this.sidebar = w}}
                style={{
                  width: stapleWidth,
                }}
                >
                { sidebar }
              </div>
          </div>
          <ul className="pillars">
            { children }
          </ul>
      </div>
    );
  }
}

export default Staple;
