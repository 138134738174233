import React, { PureComponent } from 'react';
import Select from 'react-select';

export default class MultiSelectField extends PureComponent {
  handleSelectChange (value) {
    console.log('You\'ve selected:', value);
    this.props({ value });
  }

  render () {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isFocused ? '#E5E5E5' : 'white',
        ':hover': {
          backgroundColor: '#E5E5E5',
        },
      }),
      // indicatorSeparator: (provided) => ({
      //   ...provided,
      //   width: '2px',
      //   backgroundColor: '#1B465D',
      // }),
      input: (provided) => ({
        ...provided,
        ' input': {
          margin: '0px !important',
        },
      }),
      control: () => ({
        borderRadius: '4px',
        display: 'flex',
        padding: '0.75rem 0',
        border: '2px solid #1B465D',
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: '#E5E5E5',
        ':hover': {
          backgroundColor: '#CCCCCC',
        },
      }),
    }
    const { value, options, onChange } = this.props;
    // filterOption={createFilter({ignoreCase: false})}
    return (
      <Select
        closeOnSelect={false}
        styles={customStyles}
        isMulti
        isClearable
        hideSelectedOptions
        onChange={onChange}
        options={options}
        placeholder="Select all that apply"
        simpleValue
        value={value}
      />
    );
  }
}
