import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import RecommendationList from '../components/RecommendationList';
import StapledSidebar from '../components/StapledSidebar';
import MultipleChoiceDropdown from '../components/MultipleChoiceDropdown';
import { Organization, Candidate } from '../models/recommendations';
import Terms from '../models/Terms';
import * as AdvisingActions from '../actions/AdvisingActions';
import { FORM_QUESTIONS, QuestionTypes } from '../constants/FormQuestions';
import { scrollToLink } from '../utils/ScrollUtils';

import './AdvisingPage.scss';

const enhance = compose(
  withRouter,
  firebaseConnect((props) => [
    { path: 'organizations', queryParams: ['orderByChild=profileComplete', 'equalTo=1'] },
    { path: 'candidates', queryParams: ['orderByChild=profileComplete', 'equalTo=1'] },
    { path: 'terms' },
  ]),
  connect(
    ({ firebase, filters }) => ({
      rawOrganizations: firebase.data['organizations'],
      rawCandidates: firebase.data['candidates'],
      rawTerms: firebase.data['terms'],
      filters: filters,
    }),
    (dispatch, ownProps) => ({
      setFilterDropdown: (question, value) => {
        dispatch(AdvisingActions.setFilter(question, value));
        // switch (action.action) {
        //   case 'select-option':
        //     dispatch(AdvisingActions.setFilterMulti(question, action.value, true));
        //   case 'clear':
        //     dispatch(AdvisingActions.clearFilter(question));
        //   default:
        //     break;
        // }
      },
      setFilterCheckbox: (question, key) => ev => {
        dispatch(AdvisingActions.setFilterMulti(question, key, ev.target.checked));
      },
      setFilter: (question, value) => ev => {
        dispatch(AdvisingActions.setFilter(question, value));
      },
    })
  )
);

class Advising extends Component {

  _removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  constructor() {
    super();
    this.state = {advising_mode: null}
  }

  componentWillMount() {
    const { history } = this.props;

    history.replace('/advising');

    // firebase.remoteConfig().fetchAndActivate().then(() => {
    //     this.setState({advising_mode: firebase.remoteConfig().getValue('advising_mode')})
    // }).catch((err) => {
    //     console.error(err);
    // });
  }

  scoreResponses(responses) {
    const { filters } = this.props;
    if (!filters || filters.length === 0) {
      return responses;
    }

    const scoredResponses = responses.map(r => ({
      score: 0,
      justifications: [],
      response: r,
    }));

    return FORM_QUESTIONS.reduce((scoredResponses, {id, type}) => {
      if (!filters[id] || Object.keys(filters[id]).length === 0) {
        return scoredResponses;
      }

      switch (type) {
        case QuestionTypes.MULTIPLE_CHOICE_DROPDOWN:
          return scoredResponses.map(({score, justifications, response}) => {
            if (!response[id]) {
              return {score, justifications, response};
            }

            // Single value
            if (typeof response[id] === 'string') {
              const hasMatches = filters[id].some(filterOpt => filterOpt.value === response[id]);
              if (hasMatches) {
                return {
                  score: score + 1,
                  justifications: justifications.concat(response[id]),
                  response,
                };
              }
              else {
                return {score, justifications, response};
              }
            }

            // As list
            const matches = filters[id].filter(filterOpt => response[id].some(opt => filterOpt.value === opt));
            return {
              score: score + matches.length,
              justifications: justifications.concat(matches.map(m => m.value)),
              response,
            };
          });
        case QuestionTypes.MULTIPLE_CHOICE:
          return scoredResponses.map(({score, justifications, response}) => {
            if (!response[id]) {
              return {score, justifications, response};
            }

            // Single value
            if (typeof response[id] === 'string') {
              const hasMatches = filters[response[id]];
              if (hasMatches) {
                return {
                  score: score + 1,
                  justifications: justifications.concat(response[id]),
                  response,
                };
              }
              else {
                return {score, justifications, response};
              }
            }

            // As list
            const matches = response[id].filter(opt => filters[id][opt])
            return {
              score: score + matches.length,
              justifications: justifications.concat(matches),
              response,
            };
          });
        default:
          return scoredResponses;
      }
    }, scoredResponses);
  }

  renderQuestions() {
    const { filters, setFilter, setFilterCheckbox, setFilterDropdown } = this.props;
    if (!filters) {
      return (<span>Loading...</span>)
    }
    return (
      FORM_QUESTIONS.map((q, qIdx) => {
        const nextQuestionId = ((qIdx === FORM_QUESTIONS.length - 1) ? 'recommendations' : FORM_QUESTIONS[qIdx + 1].id);
        switch (q.type) {
          case QuestionTypes.SINGLE_CHOICE:
            return (
              <div className="question" key={q.id} id={q.id}>
                <h3>{qIdx + 1}. { q.question }</h3>
                <h5 className="very-light">Select one</h5>
                <ul className={`form-control options ${q.type} ${(filters[q.id] && Object.keys(filters[q.id]).length > 0) ? '' : 'none-selected'}`}>
                  {
                    q.options.map((op, opIdx) => (
                      <li className="option" key={opIdx}>
                        <input
                          tabIndex="0"
                          type="radio"
                          id={ op.htmlId }
                          checked={(filters[q.id] && (filters[q.id] === op.value)) || ""}
                          onChange={setFilter(q.id, op.value)}
                          value={ op.htmlId }
                          name={ q.id } />
                        <label htmlFor={ op.htmlId }>{ op.label }</label>
                      </li>
                    ))
                  }
                </ul>
                <a
                  className="skip-question text-button"
                  onClick={scrollToLink(nextQuestionId)}
                  href={'#' + nextQuestionId}>I don't know, skip this.</a>
              </div>
            )
          case QuestionTypes.MULTIPLE_CHOICE:
            return (
              <div className="question" key={q.id} id={q.id}>
                <h3>{qIdx + 1}. { q.question }</h3>
                <h5 className="very-light">Select all that apply</h5>
                <ul className={`form-control options ${q.type} ${(filters[q.id] && Object.keys(filters[q.id]).length > 0) ? '' : 'none-selected'}`}>
                  {
                    q.options.map((op, opIdx) => (
                      <li className="option" key={opIdx}>
                        <input
                          tabIndex="0"
                          type="checkbox"
                          id={ op.htmlId }
                          checked={(filters[q.id] && filters[q.id][op.value]) || ""}
                          onChange={setFilterCheckbox(q.id, op.value)}
                          name={ op.htmlId } />
                        <label htmlFor={ op.htmlId }>
                          <span className="label">{ op.label }</span>
                          { op.description && (
                            <span className="description">
                              { op.description }
                            </span>
                          )}
                        </label>
                      </li>
                    ))
                  }
                </ul>
                <a
                  className="skip-question text-button"
                  onClick={scrollToLink(nextQuestionId)}
                  href={'#' + nextQuestionId}>I don't know, skip this.</a>
              </div>
            )
          case QuestionTypes.MULTIPLE_CHOICE_DROPDOWN:
            // console.log(filters[q.id])
            return (
              <div className="question" key={q.id} id={q.id}>
                <h3>{qIdx + 1}. { q.question }</h3>
                <MultipleChoiceDropdown
                  options={q.options}
                  tabIndex="0"
                  onChange={value => setFilterDropdown(q.id, value)}
                  value={filters[q.id]}
                  />
                { q.suggestions && (
                  <h5 className="suggestions">
                    <label>Try: </label>
                    <span className="suggestions-list">
                    {
                      q.suggestions.map((sugg, sIdx) => {
                        let options = this._removeDuplicates(sugg.options.concat(filters[q.id] || []), 'htmlId')
                        options.sort((a, b) => (a.htmlId < b.htmlId ? -1 : 1));
                        return (
                          <span className="suggestion"
                                key={sIdx}
                                tabIndex="0"
                                onClick={() => setFilterDropdown(
                                  q.id,
                                  options
                                )}>
                            {sugg.label}
                          </span>
                        );
                      })
                    }
                    </span>
                  </h5>
                )}
                <a
                  className="skip-question text-button"
                  onClick={scrollToLink(nextQuestionId)}
                  href={'#' + nextQuestionId}>I don't know, skip this.</a>
              </div>
            )
          default:
            return "";
        }
      })
    );
  }

  render() {
    const { rawCandidates, rawOrganizations, rawTerms, filters } = this.props;
    const terms = rawTerms && new Terms(rawTerms);
    const scoredCandidates = rawCandidates && this.scoreResponses(rawCandidates.map(c => new Candidate(c)));
    const scoredOrganizations = rawOrganizations && this.scoreResponses(rawOrganizations.map(o => new Organization(o)));
    return (
      <div className="Advising">
        <Navbar />
        <section className="section-hero">
          <div className="content">
            <header>
              <h2>Grassroots money matters more than ever before, but a lot of potential donors don’t know where to start. We do.</h2>
            </header>
          </div>
        </section>
        <section>
          <form>
            <StapledSidebar sidebar={(
                <div className="m-hidden">
                  <h3 className="m-h2">Select your preferences</h3>
                  <ol>
                  {
                    FORM_QUESTIONS.map((question, qIdx) => (
                      <li className="jumplink" key={qIdx}>
                        <a key={question.id}
                           href={'#' + question.id}
                           onClick={scrollToLink(question.id)}>
                          <h5>{question.title}</h5>
                        </a>
                      </li>
                    ))
                  }
                  </ol>
                  <div className="button-group">
                    <a href="#recommendations"
                       className="button blue"
                       onClick={scrollToLink('recommendations')}>See Recommendations</a>
                  </div>
                </div>
              )}>
              { this.renderQuestions() }
            </StapledSidebar>
          </form>
        </section>
        <section id="recommendations">
          <StapledSidebar sidebar={(
            <React.Fragment>
              <h3 className="close">Our suggested candidates &amp; organizations</h3>
              <p className="small">
                { (Object.keys(filters).length === 0) ? (
                  <span>We’ve recommended some candidates and organizations by default, since you have not expressed preferences.</span>
                ) : (
                  <span>Selected based on your preferences. <a href="https://thenext50.us/methodology/">Learn how we choose the best match</a>.</span>
                )}
              </p>
              <p className="light small m-hidden">Want more recommendations? Sign up for our newsletter which includes monthly suggestsions for where is best to donate.</p>
              <form action="https://gmail.us20.list-manage.com/subscribe/post?u=81c37f99ad56349dc2bd34dd0&amp;id=2eecc2a2c7" method="post" name="mc-embedded-subscribe-form" className="validate m-hidden" target="_blank" noValidate>
                <input type="email" placeholder="Email address..." name="EMAIL" className="required email" />
                <div style={{
                       position: 'absolute',
                       left: '-5000px',}}
                     aria-hidden="true">
                  <input type="text" name="b_81c37f99ad56349dc2bd34dd0_2eecc2a2c7" tabIndex="-1" />
                </div>
                <input type="submit" value="Subscribe" name="subscribe" className="button blue" />
             </form>
            </React.Fragment>
            )}>
            <RecommendationList scoredCandidates={scoredCandidates} scoredOrganizations={scoredOrganizations} terms={terms} />
          </StapledSidebar>
        </section>
        <section>
          <div className="content">
            <h3>Not seeing an organization or candidate you're expecting?</h3>
            <div className="button-group">
              <a className="button blue" href="#">Suggest an organization or candidate</a>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default enhance(Advising);

