import React, { PureComponent } from 'react';

import './Footer.scss';

class Footer extends PureComponent {
  render() {
    // const loggedOut = (window.location.pathname === '/');
    return (
      <section className="footer-wrapper">
          <div className="content">
              <h2 className="text-content">Get involved with the next generation of politics</h2>
              <div className="email-area">

              </div>
          </div>
          <div className="content">
              <footer>
                  <div className="logo-area">
                      <img className="logo" src="/img/thenext50-white.svg" alt="" />
                      <div className="email-form" id="mc_embed_signup">
                          <form action="https://thenext50.us20.list-manage.com/subscribe/post?u=81a42c1c484a406e56fdb71a7&amp;id=6448fd4af9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                              <div id="mc_embed_signup_scroll">
                                  <div className="mc-field-group">
                                      <input type="email" placeholder="Email address..." name="EMAIL" className="required email" id="mce-EMAIL" />
                                  </div>
                                  <div style={{position: 'absolute', 'left': '-5000px'}} aria-hidden="true"><input type="text" name="b_81a42c1c484a406e56fdb71a7_6448fd4af9" tabIndex="-1" /></div>
                                  <div className="clear submit-wrapper"><input type="submit" value="Keep updated" name="subscribe" id="mc-embedded-subscribe" className="button blue full-width" /></div>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="right-side">
                      <div className="columns">
                          <div className="column">
                              <h5>The Next 50</h5>
                              <h6><a href="/about" title="About">About</a></h6>
                              <h6><a href="/events" title="Events">Events</a></h6>
                              <h6><a href="/advising" title="Advising">Advising</a></h6>
                          </div>
                          <div className="column">
                              <h5>Connect with us</h5>
                              <h6><a href="https://www.facebook.com/groups/2422608677784519/" title="Facebook">Facebook</a></h6>
                              <h6><a href="https://twitter.com/thenext50us" title="Twitter">Twitter</a></h6>
                              <h6><a href="https://www.instagram.com/thenext50us/" title="Instagram">Instagram</a></h6>
                          </div>
                      </div>
                      <h6 className="notice">Paid for by The Next 50 PAC, <a href="https://thenext50.us">thenext50.us</a>, not authorized by any candidate or candidate’s committee.</h6>
                      <h6 className="copy">&copy; The Next 50, 2019</h6>
                  </div>
              </footer>
          </div>
      </section>
    );
  }
}

export default Footer;
