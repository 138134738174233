import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './Navbar.scss';
import logo from '../img/thenext50.svg';
import classNames from 'classnames';

const enhance = compose(
  withFirebase,
  withRouter,
  connect(
    ({ firebase }) => ({
      auth: firebase.auth,
    }),
    null
  )
);


class Navbar extends Component {

  constructor() {
    super();
    this.state = { isOpen: false, isPinned: false };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.logDonate = this.logDonate.bind(this);
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }


  logDonate() {
    const { firebase } = this.props;
    firebase.analytics().logEvent('page_view');
  }

  render() {
    // const { auth } = this.props;
    const loggedOut = (window.location.pathname === '/');
    const className = classNames(
      "Navbar",
      {"nav-open": this.state.isOpen},
      {"is-pinned": this.state.isPinned},
      {"logged-out": loggedOut},
    );

    return (
      <div className={className}>
        <nav id="nav">
          <a href="/?anim=off" className="logo">
            <img src={logo} alt="The Next 50 Logo"/>
          </a>
          <div className="spacer"></div>
          <div className="hamburger" id="hamburger">
            <div className="ham"></div>
            <div className="bur"></div>
            <div className="ger"></div>
          </div>
          <ul>
            <li><a href="https://thenext50.us/about" title="About">About</a></li>
            <li><a href="https://thenext50.us/events" title="Events">Events</a></li>
            <li className="selected"><a href="/advising" title="Advising">Advising</a></li>
            <li>
              <a href="https://secure.actblue.com/donate/thenext50?refcode=web&recurring=1"
                 title="Donate"
                 onClick={this.logDonate}
                 className="highlight-button yellow">Donate</a>
              </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default enhance(Navbar);
